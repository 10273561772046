import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import Header from './header'
import 'bootstrap/dist/css/bootstrap.min.css';
import './layout.scss';

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            { name: 'description', content: 'Marita S. Grobbel Advisory Services was started in 2013 to provide C-Level executive services to business owners and CEOs dealing with the challenges of growth and innovation. We partner with business leaders of small to mid-market companies, working within their environment to provide business acceleration initiatives to capture next-stage growth opportunities.' },
            { name: 'keywords', content: 'Marita Grobbel, Marita S. Grobbel Advisory Services, executive services, C-Level Business Executive Services, growth, innovation, Grosse Pointe, Detroit, Strategic advice to help you grow' },
            { name: 'robots', content: 'index, follow' }
          ]}
        >
          <html lang="en" />
        </Helmet>
        <div className="wrapper">
          <Header siteTitle={data.site.siteMetadata.title} />
          <div id="body">
            {children}
          </div>
          <div className="push"></div>
        </div>
        <footer>
          <div className="footer">
            <div className="text-center">
              &copy; {new Date().getFullYear()} MSG Advisory Services
          </div>
          </div>
        </footer>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
