import React from 'react'

import Layout from '../components/layout'

import bannerImage from '../images/renaissance-center.jpg';

const IndexPage = () => (
  <Layout>
    <div className="page page-home container">
      <div className="row hero">
        <div className="col-xs-12">
          <img src={bannerImage} className="img-responsive" alt="Detroit Renaissance Center" />
        </div>
      </div>

      <div className="row">
        <div className="col-xs-12">
          <div className="well text-center">
            <p>
              MSG Advisory Services collaborates at the C-level to provide strategic advice, support, and counsel to small to mid-size companies.
            </p>
          </div>
        </div>
      </div>

      <div className="row capabilities hidden-xs hidden-sm">
        <div className="col-xs-12">
          <div className="text-center">
            Guide | Identify | Define | Focus | Provide
            </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xs-12">
          <div className="well">
            <div className="row">
              <div className="col-xs-12">
                <h3>Our Approach</h3>
                <p>We specialize in providing strategic advice, support, and counsel to small to mid-size companies.</p>

                <p>With over 20 years' experience as C-level executives we partner and collaborate with business leaders, working within your environment to identify opportunities, optimize existing operations, support leadership, and implement measurable results via:</p>
                <ul>
                  <li>Guiding C-Level Management</li>
                  <li>Identifying opportunities for growth</li>
                  <li>Defining goals</li>
                  <li>Focusing teams</li>
                  <li>Providing measurable results</li>
                </ul>

                <p>Many businesses are beginning to recognize the need for an executive-level role focused exclusively on driving growth for best results. MSG goes beyond offering a consultation service, and position ourselves within your organization to provide support to the executive team as a collaborative business advisor, a trusted partner, or a member of the board.</p>

                <p>By aligning ourselves as a senior advisor within your organization, we can guide, identify, define, and focus the areas of the business that have the opportunity for growth, and provide you with measurable results, and the tools to go forward.</p>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

  </Layout>
)

export default IndexPage
