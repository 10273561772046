import React from 'react'
import { Link } from 'gatsby'

import headerLogo from "../images/logo-header.png";

const Header = () => (
  <header>
    <nav className="navbar navbar-default navbar-fixed-top">
      <div className="header-oneliner primary hidden-xs hidden-sm">
        Strategic advice to help you grow.
    </div>
      <div className="container">
        <div className="navbar-header">
          <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target=".navbar-collapse">
            <span className="sr-only">Toggle navigation</span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </button>
          <Link to="/" className="navbar-brand">
            <img src={headerLogo} alt="MSG Advisory" />
          </Link>
        </div>
        <div className="navbar-collapse collapse">

          <ul className="nav navbar-nav navbar-right">
            <li><Link to="/">Home</Link></li>
            <li><Link to="/meet-marita/">Meet Marita</Link></li>
            <li><Link to="/about-msg/our-services/">Our Services</Link></li>
            <li><Link to="/about-msg/why-hire-msg/">Why Hire MSG</Link></li>
          </ul>
        </div>
        <div className="container">

        </div>
        {/* /.nav-collapse */}
      </div>
      <div className="header-oneliner visible-xs visible-sm">
        Strategic advice to help you grow.
    </div>
      {/* /.container-fluid */}
    </nav>
  </header>
)

export default Header
